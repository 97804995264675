<template>
  <div class="pd10">
    <el-card class="custom-card">
      <div slot="header" class="clearfix">
        <span class="border-l relative pdL10">{{$route.meta.title}}</span>
        <!-- <el-button class="right" type="text" @click="add">添加</el-button> -->
      </div>
      <el-button type="success" @click="add" plain class="mgB10">添加</el-button>
      <el-table :data="tableData" border stripe v-loading="loading" style="width: 100%;">
        <el-table-column prop="houseNo" :show-overflow-tooltip="true" header-align="center" align="center" label="仓房名称">
        </el-table-column>
        <el-table-column prop="cangName" :show-overflow-tooltip="true" header-align="center" align="center"
          label="仓房类型"></el-table-column>
        <el-table-column prop="mainNo" :show-overflow-tooltip="true" header-align="center" align="center" label="分机号">
        </el-table-column>
        <el-table-column prop="mainInNo" :show-overflow-tooltip="true" header-align="center" align="center"
          label="仓温仓湿"></el-table-column>
        <el-table-column prop="high" :show-overflow-tooltip="true" header-align="center" align="center" label="测温点层数">
        </el-table-column>
        <el-table-column prop="col" :show-overflow-tooltip="true" header-align="center" align="center" label="测温点列数">
        </el-table-column>
        <el-table-column prop="row" :show-overflow-tooltip="true" header-align="center" align="center" label="测温点行数">
        </el-table-column>
        <el-table-column prop="startCode" :show-overflow-tooltip="true" header-align="center" align="center"
          label="起始点"></el-table-column>
        <el-table-column prop="endCode" :show-overflow-tooltip="true" header-align="center" align="center" label="终止点">
        </el-table-column>
        <el-table-column prop="whetherWired" :show-overflow-tooltip="true" header-align="center" align="center"
          label="是否有线">
          <template slot-scope="scope">
            <el-tag :type="scope.row.whetherWired ? 'primary' : 'success'" disable-transitions>
              {{scope.row.whetherWired?'有线':'无线'}}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="highestTemp" :show-overflow-tooltip="true" header-align="center" align="center"
          label="温度上限"></el-table-column>
        <el-table-column prop="minimumTemp" :show-overflow-tooltip="true" header-align="center" align="center"
          label="温度下限"></el-table-column>
        <el-table-column label="操作" width="120" header-align="center" align="center" fixed="right">
          <template slot-scope="scope">
            <el-button @click.native.prevent="editRow(scope.row)" size="mini" class="custom-table-btn">
              编辑
            </el-button>
            <el-button @click.native.prevent="delRow(scope.row)" type="danger" size="mini" class="custom-table-btn">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <!-- 编辑  添加-->
    <el-dialog class="custom-dialog" :title="formType?'编辑':'添加'" :visible.sync="dialogVisible" width="620px"
      :append-to-body="true" @close="closeAlert">
      <el-form ref="form" :rules="formRules" :model="form" label-width="90px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="仓房名称" prop="houseNo" class="custom-form-item">
              <el-input v-model="form.houseNo" placeholder="请输入仓房名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="仓房类型" prop="cangType" class="custom-form-item">
              <el-select v-model="form.cangType" :disabled="formType" style="width: 100%;" placeholder="请选择仓房类型">
                <el-option v-for="( item,index ) in typeList" :key="index" :value="item.cangType"
                  :label="item.cangName"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="分机号" prop="mainNo" class="custom-form-item">
              <el-input-number style="width: 100%;" @change="getStartCode" v-model="form.mainNo" :min="0"
                label="请输入分机号"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="仓温仓湿" prop="mainInNo" class="custom-form-item">
              <el-input-number style="width: 100%;" v-model="form.mainInNo" label="请输入仓温仓湿"></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12" v-if="form.cangType===0">
            <el-form-item label="起始点位置" prop="startPointPosition" class="custom-form-item">
              <el-select v-model="form.startPointPosition" style="width: 100%;" placeholder="请选择起始点位置">
                <el-option v-for="( item,index ) in startPointPositions" :key="index" :value="item.value"
                  :label="item.name"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="是否有线" prop="whetherWired" class="custom-form-item">
              <el-radio-group v-model="form.whetherWired" @change="getStartCode">
                <el-radio :label="false">无线</el-radio>
                <el-radio :label="true">有线</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="起始点" prop="startCode" class="custom-form-item">
              <el-input-number style="width: 100%;" v-model="form.startCode" label="请输入起始点"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="层数" prop="high" class="custom-form-item">
              <el-input v-model="form.high" placeholder="请输入测温点层数"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="列（根）数" prop="col" class="custom-form-item">
              <el-input v-model="form.col" placeholder="请输入测温点列（根）数"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="行（圈）数" prop="row" class="custom-form-item">
              <el-input v-model="form.row" placeholder="请输入测温点行（圈）数"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="终止点" prop="endCode" class="custom-form-item">
              <el-input placeholder="请计算终止点" readonly v-model="form.endCode" class="input-with-select">
                <el-button slot="append" @click="calcEndCode">计算</el-button>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="温度上限" prop="highestTemp" class="custom-form-item">
              <el-input v-model="form.highestTemp" type="number" placeholder="请输入温度上限"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="温度下限" prop="minimumTemp" class="custom-form-item">
              <el-input v-model="form.minimumTemp" type="number" placeholder="请输入温度下限"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="bottom-btn">
        <el-button type="primary" @click="onSubmit('form')" :loading="loadingAlert" :disabled="loadingAlert">确 定
        </el-button>
        <el-button @click="closeAlert">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  const startPointPositions = [{
    name: '左上',
    value: 0
  }, {
    name: '左下',
    value: 1
  }, {
    name: '右上',
    value: 2
  }, {
    name: '右下',
    value: 3
  }]
  export default {
    data() {
      return {
        startPointPositions: startPointPositions,
        typeList: [],
        tableData: [],
        loading: false,
        dialogVisible: false,
        formType: false,
        form: {
          id: "",
          houseNo: "",
          mainNo: "",
          mainInNo: "",
          startCode: "",
          endCode: "",
          col: "",
          row: "",
          high: "",
          cangType: "",
          whetherWired: false,
          startPointPosition: "",
          highestTemp: "",
          minimumTemp: ""
        },
        loadingAlert: false,
        formRules: {
          highestTemp: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
          minimumTemp: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
          startPointPosition: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
          high: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
          cangType: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
          whetherWired: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
          houseNo: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
          mainNo: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
          mainInNo: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
          startCode: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
          endCode: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
          col: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
          row: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
        }
      }
    },
    created() {
      this.getAbarnAll()
      this.cfCangTypeList()
    },
    methods: {
      //添加
      add() {
        this.dialogVisible = true;
      },
      //获取起始点
      getStartCode() {
        let params = '?mainNo=' + this.form.mainNo + '&whetherWired=' + this.form.whetherWired
        if (this.formType) params += '&id=' + this.form.id;
        this.$axios.Get((this.formType ? this.$api.cfAbarnStartCodeNe : this.$api.cfAbarnStartCode) + params, {}).then(
          res => {
            this.form.startCode = res.data;
          })
      },
      //计算终止点
      calcEndCode() {
        if (!this.form.cangType && this.form.cangType !== 0) {
          this.$notify({
            title: '警告',
            message: '仓房类型不可为空',
            type: 'warning'
          });
          return;
        }
        if (!this.form.high && this.form.high !== 0) {
          this.$notify({
            title: '警告',
            message: '测温点层数不可为空',
            type: 'warning'
          });
          return;
        }
        if (!this.form.col && this.form.col !== 0) {
          this.$notify({
            title: '警告',
            message: '测温点（根）数不可为空',
            type: 'warning'
          });
          return;
        }
        if (!this.form.row && this.form.row !== 0) {
          this.$notify({
            title: '警告',
            message: '测温点（圈）数不可为空',
            type: 'warning'
          });
          return;
        }
        this.$axios.Get(this.$api.cfAbarnCodeSize, this.form).then(
          res => {
            this.form.endCode = res.data;
          })
      },
      //获取仓房类型
      cfCangTypeList() {
        this.$axios.Get(this.$api.cfCangTypeList, {}).then(res => {
          this.typeList = res.data;
        })
      },
      //获取仓房信息
      getAbarnAll() {
        this.loading = true;
        this.$axios.Get(this.$api.getAbarnAll, {}).then(res => {
          this.tableData = res.data;
          this.loading = false;
        }).catch(() => {
          this.loading = false;
        })
      },
      //编辑
      editRow(row) {
        this.form = JSON.parse(JSON.stringify(row))
        this.formType = true;
        this.dialogVisible = true;
      },
      //删除
      delRow(e) {
        this.$confirm('此操作将永久删除该数据吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let than = this;
          this.$axios.Post(this.$api.cfAbarnGetDel, {
            "id": e.locaId
          }).then(() => {
            this.$message({
              message: '删除成功',
              type: 'success'
            });
            than.getAbarnAll()
          }).catch((err) => {
            if (err.errMsg) {
              this.$message({
                type: 'error',
                message: err.errMsg
              });
            }
          })
        }).catch(() => {});
      },
      //提交
      onSubmit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.formType ? this.editUser() : this.addUser()
          } else {
            return false;
          }
        });
      },
      //添加信
      addUser() {
        this.loadingAlert = true
        this.$axios.Post(this.$api.cfAbarnGetAdd, this.form).then(() => {
          this.$message({
            type: 'success',
            message: '添加成功!'
          });
          // this.closeAlert();
          this.getAbarnAll()
        }).catch((err) => {
          if (err.errMsg) {
            this.$message({
              type: 'error',
              message: err.errMsg
            });
          }
          this.loadingAlert = false
        })
      },
      //编辑信息
      editUser() {
        this.loadingAlert = true
        this.$axios.Post(this.$api.cfAbarnGetModify, this.form).then(() => {
          this.$message({
            type: 'success',
            message: '修改成功!'
          });
          this.closeAlert();
          this.getAbarnAll()
        }).catch((err) => {
          if (err.errMsg) {
            this.$message({
              type: 'error',
              message: err.errMsg
            });
          }
          this.loadingAlert = false
        })
      },
      //关闭
      closeAlert() {
        this.formType = false;
        this.dialogVisible = false;
        this.$refs["form"].resetFields();
        this.form.id = "";
        this.loadingAlert = false;
      }
    }
  }
</script>

<style>
</style>
